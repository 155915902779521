
/* スクロールアニメーション
    -------------------------------------------------------------------------- */
const scrollAnimation = () => {
  const target = document.querySelectorAll('.fade-in,.fade-up,.fade-right,.fade-left');

  for (let _i = 0; _i < target.length; _i++){
    const rect = target[_i].getBoundingClientRect().top;
    const scroll = window.pageYOffset || document.documentElement.scrollTop;
    const offset = rect + scroll;
    const windowHeight = window.innerHeight;
    if (scroll > offset - windowHeight + 150) {
      target[_i].classList.add('is-show');
    }
  }
};
window.addEventListener('scroll', scrollAnimation);

/* モーダルナビ
   -------------------------------------------------------------------------- */
const body = document.body;
const gnav = document.querySelector('.js-gnav');
const gnavToggle = document.querySelector('.js-gnav-toggle');

gnavToggle.addEventListener('click', () => {
  if (gnav.classList.contains('is-active') == true) {
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);

    gnav.classList.remove('is-active');
  } else {
    const _scrollY = document.documentElement.style.getPropertyValue('--scroll-y');

    gnav.classList.add('is-active');

    body.style.position = 'fixed';
    body.style.top = '-' + _scrollY;
  }
});

window.addEventListener('scroll', () => {
  document.documentElement.style.setProperty('--scroll-y', window.scrollY + 'px');
});

/* ページ内リンク
   -------------------------------------------------------------------------- */
const pageLink = () => {
  const link = document.querySelectorAll('a[href^="#"]');
  for (let _i = 0; _i < link.length; _i++){
    link[_i].addEventListener('click', function(e) {
      e.preventDefault();
      if (window.matchMedia('(max-width:1200px)').matches) {
          
        const scrollY = body.style.top;
        body.style.position = '';
        body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
    
        gnav.classList.remove('is-active');
      }

      let _href = link[_i].getAttribute('href');
      let _targetElement = document.getElementById(_href.replace('#', ''));
      const rect = _targetElement.getBoundingClientRect().top;
      const offset = window.pageYOffset;
      const headerHeight = document.getElementsByClassName('p-header')[0].clientHeight;
      const target = rect + offset - headerHeight;
      window.scrollTo({
        top: target,
        behavior: 'smooth',
      });
    });
  }
};
document.addEventListener('DOMContentLoaded', pageLink);



/* トップへ戻るボタン
    -------------------------------------------------------------------------- */
const pageTopButton = document.querySelector('.js-button-top');

const pageTop = () => {
  if (window.pageYOffset > 100) {
    pageTopButton.classList.add('is-show')
  } else {
    pageTopButton.classList.remove('is-show')
  }

  pageTopButton.addEventListener('click', () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    setTimeout(() => {
      pageTopButton.classList.remove('is-show')
    }, 500);
  });
};
window.addEventListener('scroll', pageTop);


/* READ MOREボタン
   -------------------------------------------------------------------------- */
const readMoreButton = document.querySelectorAll('.js-button-more');
const readMoreContent = document.querySelectorAll('.js-text-box');

const readMore = () => {
  const toggleClass = (element) => {
    return element.classList.toggle("is-active");
  };

  const loop = (_i) => {
    readMoreButton[_i].addEventListener('click', () => {
      toggleClass(readMoreContent[_i]);
      toggleClass(readMoreButton[_i]);
    });
  };

  for (let _i = 0; _i < readMoreButton.length; _i++) {
    loop(_i);
  }
};
document.addEventListener('DOMContentLoaded', readMore);


/* フォーム無効ボタン有効化
    -------------------------------------------------------------------------- */
const formConfirmCheck = () => {
  const check = document.querySelector('input[name="agree-check"]');
  const confirmButton = document.forms['input-form'].elements['confirm'];
  confirmButton.disabled = true;

  check.addEventListener('change', function() {
    if (this.checked) {
      confirmButton.disabled = false;
    } else {
      confirmButton.disabled = true;
    }
  }, false);
};
document.addEventListener('DOMContentLoaded', formConfirmCheck);

const formSendCheck = () => {
const check = document.querySelector('input[name="agree-check"]');
  const sendButton = document.forms['confirm-form'].elements['send'];
  sendButton.disabled = false;

  check.addEventListener('change', function() {
    if (this.checked) {
      sendButton.disabled = false;
    } else {
      sendButton.disabled = true;
    }
  }, false);
};
document.addEventListener('DOMContentLoaded', formSendCheck);